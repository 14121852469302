import React from "react";
import { Link } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import { makeStyles } from "@material-ui/core/styles";
import twitter from "../../assets/twitter.svg";
import instagram from "../../assets/instagram.svg";

const Footer = (props) => {
  const useStyles = makeStyles((theme) => ({
    footer: {
      backgroundColor: theme.palette.primary.main,
      zIndex: 1301,
      position: "relative",
    },
    gridItem: {
      margin: "3em",
      marginTop: "2em",
      marginBottom: "0",
    },
    icon: {
      height: "4em",
      width: "4em",
      [theme.breakpoints.down("md")]: {
        height: "2.5em",
        width: "2.5em",
      },
    },
    link: {
      color: "Black",
      fontFamily: "Arial",
      fontSize: "1.25rem",
      fontWeight: "bold",
      textDecoration: "none",
    },
    mainContainer: {
      position: "relative",
      height: "3em",
    },
    socialContainer: {
      position: "absolute",
      marginTop: "-3em",
      right: "1.5em",
      [theme.breakpoints.down("md")]: {
        right: "0.6em",
      },
      [theme.breakpoints.down("sm")]: {
        marginTop: "-12em",
      },
    },
  }));
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Hidden mdDown>
        <Grid
          container
          className={classes.mainContainer}
          direction='row'
          justify='center'
          alignItems='center'
        >
          <Grid item className={classes.gridItem}>
            <Grid container direction='column' spacing={2}>
              <Grid
                item
                onClick={() => props.setValue(0)}
                component={Link}
                to='/'
                className={classes.link}
              >
                Home
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={classes.gridItem}>
            <Grid container direction='column' spacing={2}>
              <Grid
                item
                onClick={() => {
                  props.setValue(1);
                  props.setSelectedIndex(1);
                }}
                component={Link}
                to='/services'
                className={classes.link}
              >
                Services
              </Grid>
            </Grid>
          </Grid>

          <Grid item className={classes.gridItem}>
            <Grid container direction='column' spacing={2}>
              <Grid
                item
                onClick={() => props.setValue(2)}
                component={Link}
                to='/revolution'
                className={classes.link}
              >
                NobelX
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={classes.gridItem}>
            <Grid container direction='column' spacing={2}>
              <Grid
                item
                onClick={() => props.setValue(3)}
                component={Link}
                to='/about'
                className={classes.link}
              >
                About Me
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={classes.gridItem}>
            <Grid container direction='column' spacing={2}>
              <Grid
                item
                onClick={() => props.setValue(4)}
                component={Link}
                to='/contact'
                className={classes.link}
              >
                Contact Me
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Hidden>
      <Grid
        container
        direction='row'
        className={classes.mainContainer}
        alignItems='center'
      >
        <Grid
          container
          className={classes.socialContainer}
          justify='flex-end'
          spacing={2}
        >
          <Grid
            item
            component={"a"}
            href='http://www.instagram.com'
            rel='noopener noreferrer'
            target='_blank'
          >
            <img
              src={instagram}
              className={classes.icon}
              alt='instagram-logo'
            ></img>
          </Grid>
          <Grid
            item
            component={"a"}
            href='http://www.twitter.com'
            rel='noopener noreferrer'
            target='_blank'
          >
            <img
              src={twitter}
              className={classes.icon}
              alt='twitter-logo'
            ></img>
          </Grid>
        </Grid>
      </Grid>
    </footer>
  );
};

export default Footer;
