import React from "react";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import React1 from "../assets/react-1.svg";

const Blog = () => {
  const useStyles = makeStyles(theme=>({
    jumbotron: {
      paddingTop: "3em",
      backgroundImage: `url(${React1})`,
      backgroundPosition: "center",
      backgroundAttachment: "fixed",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",

      [theme.breakpoints.down("sm")]: {
       
      },
    }
  }));
  const classes = useStyles();
  return (
    <div>
      <Container maxWidth='lg'>
        <Grid container direction='column'>
          <Grid item container direction='row' justify='center' style={{height: "100vh"}}>
            <Grid item container direction='column' md='3'>
              <Grid item>
                <Typography>Welcome to NobelX Blogs</Typography>
              </Grid>
            </Grid>
            <Grid item container direction='column' md='9' className={classes.jumbotron}>
              
            </Grid>
            
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default Blog
