import { createMuiTheme } from "@material-ui/core/Styles";

export default createMuiTheme({
    palette: {
        primary: {
            light: "#e254ff",
            main: '#aa00ff',
            dark: "#7200ca",
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
            light: '#76ffff',
            main: '#18ffff',
            dark: "#00cbcc",
        },
    },
    typography: {
        fontFamily: "raleway",
        estimate:{
            borderRadius: "50px",
            marginLeft: "50px",
            marginRight: "25px",
            fontFamily: "pacifico",
            fontSize: "1rem",
            textTransform: "none",
            color: "black"
       },
       tab: {
        fontFamily: "raleway",
        fontSize: "1rem",
        textTransform: "none",
        fontWeight: 700,
       },
       h4: {
        fontFamily: "raleway"
       }

    }

});