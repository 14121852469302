import React from "react";
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import green from "../assets/green.svg";
import jumbotron from "../assets/jumbotron.jpg"
import analytics from "../assets/analytics.svg"
import background from "../assets/background.jpg"
import socialmedia from "../assets/socialmedia.svg";
import webdesign from "../assets/webdesign.svg";
import webdeveloper from "../assets/webdeveloper.svg";

const LandingPage = () => {
  const useStyles = makeStyles((theme) => ({
    blogContainer: {
      paddingTop: "3em",
      backgroundImage: `url(${jumbotron})`,
      backgroundPosition: "center",
      backgroundAttachment: "fixed",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      height: "100vh",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        paddingTop: "3em",
        backgroundImage: `url(${background})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height: "100vh",
        width: "100%",
      },
    },
    jumbotron: {
      paddingTop: "3em",
      backgroundImage: `url(${jumbotron})`,
      backgroundPosition: "center",
      backgroundAttachment: "fixed",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      height: "100vh",

      [theme.breakpoints.down("sm")]: {},
    },
    learnButton: {
      backgroundColor: theme.palette.secondary.main,
    },
    nobelImage: {
      height: 200,
      width: 200,
      [theme.breakpoints.down("sm")]: {
        height: 150,
        width: 150,
      },
    },
    sidebar: {
      backgroundColor: theme.palette.secondary.main,
      paddingTop: "3em",
      paddingBottom: "10em",
      [theme.breakpoints.down("md")]: {
        height: "100%",
      },
    },
    specialText: {
      color: theme.palette.secondary.dark,
    },
    subtitle: {
      fontSize: "1.3rem",
      color: theme.palette.primary.main,
      fontFamily: "raleway",
    },
    title: {
      paddingLeft: "2em",
      marginBottom: "1em",
      fontFamily: "raleway",
      [theme.breakpoints.down("md")]: {
        paddingLeft: 0,
      },
    },
    tileIcon: {
      height: 300,
      width: 300,
    },
  }));
  const classes = useStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div>
      <Grid container direction='column' alignItems='center'>
        <Grid
          item
          container
          direction='row'
          className={classes.jumbotron}
          alignItems='center'
          justify='center'
        >
          <Box style={{ backgroundColor: "white", padding: "3em" }}>
            <Grid item container direction='column'>
              <Grid item container direction='row' justify='center'>
                <Grid item>
                  <Typography
                    variant='h1'
                    style={{ color: "#76ffff", fontFamily: "raleway" }}
                  >
                    Nobel
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant='h1' style={{ fontFamily: "raleway" }}>
                    X
                  </Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Typography variant='h4'>Where Succes Happens</Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid
          container
          direction={matchesMD ? "column" : "row"}
          style={{
            height: matchesMD ? undefined : "100vh",
            marginBottom: "3em",
          }}
        >
          <Grid item>
            <Typography
              variant='h4'
              gutterBottom
              align={matchesSM ? "center" : undefined}
              className={classes.title}
              style={{
                paddingLeft: matchesSM ? undefined : matchesMD ? "1em" : "2em",
                marginTop: "3em",
              }}
            >
              What we can do for you?
            </Typography>
          </Grid>

          {/*Services Row */}
          <Grid
            item
            container
            direction={matchesMD ? "column" : "row"}
            justify='space-around'
            style={{ marginTop: matchesMD ? "5em" : undefined }}
          >
            <Grid
              item
              container
              direction='column'
              md={3}
              alignItems='center'
              style={{ marginBottom: matchesMD ? "5em" : undefined }}
            >
              <Grid item>
                <Typography className={classes.subtitle} align='center'>
                  Design and Build Custom Websites
                </Typography>
              </Grid>
              <Grid item>
                <img
                  src={webdesign}
                  alt='computer'
                  className={classes.tileIcon}
                ></img>
              </Grid>
              <Grid item style={{ height: "6em" }}>
                <Typography className={classes.subtext} align='center'>
                  Using the latest technologies available I will design and
                  create an awesome website that looks great on any size device.
                </Typography>
              </Grid>
              <Grid item>
                <Button variant='contained' className={classes.learnButton}>
                  Learn More
                </Button>
              </Grid>
            </Grid>

            <Grid
              item
              container
              direction='column'
              md={3}
              alignItems='center'
              style={{ marginBottom: matchesMD ? "5em" : undefined }}
            >
              <Grid item>
                <Typography className={classes.subtitle}>
                  Social Media Marketing
                </Typography>
              </Grid>
              <Grid item>
                <img
                  src={socialmedia}
                  alt='computer'
                  className={classes.tileIcon}
                ></img>
              </Grid>
              <Grid item style={{ height: "6em" }}>
                <Typography className={classes.subtext} align='center'>
                  Increase your brands exposer, generate leads, and grow traffic
                  to your business with social media Marketing. The proof is in
                  the results.
                </Typography>
              </Grid>
              <Grid item>
                <Button variant='contained' className={classes.learnButton}>
                  Learn More
                </Button>
              </Grid>
            </Grid>

            <Grid item container direction='column' md={3} alignItems='center'>
              <Grid item>
                <Typography className={classes.subtitle}>
                  Increase online presence
                </Typography>
              </Grid>
              <Grid item>
                <img
                  src={analytics}
                  alt='google analytics'
                  className={classes.tileIcon}
                ></img>
              </Grid>
              <Grid item style={{ height: "6em" }}>
                <Typography className={classes.subtext} align='center'>
                  Ensure your business is seen by as many potential clients as
                  possible. Boost traffic, leads, calls and/or walk-ins to your
                  business.
                </Typography>
              </Grid>
              <Grid item>
                <Button variant='contained' className={classes.learnButton}>
                  Learn More
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction='row'
          style={{ height: "100vh", backgroundColor: "#aa00ff" }}
        ></Grid>
        <Grid
          item
          container
          direction={matchesMD ? "column" : "row"}
          style={{ height: matchesMD ? "100%" : "100vh" }}
        >
          <Grid
            item
            container
            direction='column'
            md={4}
            alignItems='center'
            justify='center'
            className={classes.sidebar}
          >
            <Grid item>
              <img src={green} alt='' className={classes.nobelImage}></img>
            </Grid>
            <Grid item>
              <Typography
                variant='h5'
                align='center'
                style={{ marginTop: matchesMD ? "2em" : undefined }}
              >
                We Can Fight Climate Change
                <br />
                Together
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction='column'
            md={8}
            alignItems='center'
            justify={matchesSM ? "flex-start" : "center"}
            style={{
              paddingTop: matchesSM ? "4em" : undefined,
              paddingBottom: matchesSM ? "4em" : undefined,
            }}
          >
            <Grid item>
              <Typography variant='h1'>
                Nobel<span className={classes.specialText}>X </span>
              </Typography>
              <Typography variant='h6'>
                Science, Engineering, and Technology
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant='contained'
                style={{ backgroundColor: "#00c853" }}
              >
                Coming Soon!
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction='row'
          justify='center'
          alignItems='center'
          className={classes.blogContainer}
        >
          <Box
            style={{
              backgroundColor: "white",
              height: matchesSM ? "20em" : "25em",
              width: matchesSM ? "20em" : "40em",
              padding: "2em",
            }}
          >
            <Grid
              container
              direction='column'
              alignItems='center'
              justify='center'
            >
              <Grid item>
                <Typography
                  className={classes.title}
                  style={{ fontSize: "1.3rem" }}
                >
                  Want to learn Web Development?
                </Typography>
              </Grid>
              <Grid item style={{ marginTop: matchesSM ? "0" : "2em" }}>
                <img
                  src={webdeveloper}
                  alt='developer on computer'
                  height={matchesSM ? "125" : "200"}
                  width={matchesSM ? "125" : "200"}
                ></img>
              </Grid>
              <Grid
                item
                container
                direction='row'
                style={{ marginTop: "2em" }}
                justify='center'
              >
                <Grid item container direction='column' alignItems='center'>
                  <Grid item>
                    <Typography gutterBottom paragraph>
                      For tutorials, examples, and how to's...
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Button variant='contained' color='secondary' size='large'>
                      Check out my blog!
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default LandingPage;
