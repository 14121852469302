import React, { useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import Header from "./Components/UI/Header";
import theme from "./Components/UI/Theme";
import Footer from "./Components/UI/Footer";

import Blog from "./Components/Blog";
import LandingPage from "./Components/LandingPage";
import FirstPost from "./Components/Blogs/FirstPost";

function App() {
  const [value, setValue] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(0);
  return (
    <div className='App'>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Header
            value={value}
            setValue={setValue}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
          ></Header>
          <Switch>
            <Route
              exact
              path='/'
              setValue={setValue}
              selectedIndex={selectedIndex}
              component={LandingPage}
            ></Route>
            <Route
              
              path='/blog'
              setValue={setValue}
              selectedIndex={selectedIndex}
              component={Blog}
            ></Route>

            <Route
              path='/react-material-simple-tutorial'
              setValue={setValue}
              selectedIndex={selectedIndex}
              component={FirstPost}
            ></Route>
          </Switch>
          <Footer
            value={value}
            setValue={setValue}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
          ></Footer>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;
